import React from "react";

import { motion } from "framer-motion";

import "../styles/stickers.scss";

import stickers from "../img/stickers.png";

const Stickers = () => {
  return (
    <div className="stickers">
      <div className="container">
        <motion.img
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          src={stickers}
          alt="Stickers de Luciano Cartaxo"
        />
        <div className="stickers-text">
          <h2>
            Tá procurando material do #TimeDeLula em João Pessoa para colocar
            nas redes sociais?
          </h2>
          <a
            href="https://sticker.ly/s/WV996K"
            target="_blank"
            rel="noreferrer"
          >
            Baixe aqui
          </a>
        </div>
      </div>
    </div>
  );
};

export default Stickers;
