import React from "react";
import "./App.css";

import Header from "./components/Header";
import Work from "./components/Work";
import WorkToDo from "./components/WorkToDo";
import Stickers from "./components/Stickers";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Work />
      <WorkToDo />
      <Stickers />
      <Gallery />
      <Footer />
    </div>
  );
};

export default App;
