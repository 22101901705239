import React from "react";

import "../styles/gallery.scss";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="container">
        <h1>Galeria</h1>
        <iframe
          title="Galeria"
          src="https://e60bf558ec5442468887200b08d7fb64.elf.site"
          width="100%"
          height="920"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Gallery;
