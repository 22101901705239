import { motion } from "framer-motion";
import { useState } from "react";

import "../styles/work.scss";

import icon21 from "../img/icons/row-2-1.png";
import icon22 from "../img/icons/row-2-2.png";
import icon23 from "../img/icons/row-2-3.png";
import icon24 from "../img/icons/row-2-4.png";
import icon25 from "../img/icons/row-2-5.png";

const Work = () => {
  const [is1Open, setIs1Open] = useState(false);
  const [is2Open, setIs2Open] = useState(false);
  const [is3Open, setIs3Open] = useState(false);
  const [is4Open, setIs4Open] = useState(false);
  const [is5Open, setIs5Open] = useState(false);
  return (
    <div className="main-work">
      <div className="list-2">
        <div className="container">
          <h1>Luciano vai fazer muito mais</h1>
          <p>
            Meu compromisso com João Pessoa é <b>fazer muito mais e melhor</b>{" "}
            do que já fiz pela nossa gente.
          </p>
          <ul className="card">
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs1Open(!is1Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon21} alt="Saúde" />
                <h2>Saúde</h2>
              </motion.div>
              {is1Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <ul className="to-do-list">
                    <li>
                      Construção do{" "}
                      <b>Hospital Geral de Urgência e Emergência.</b>
                    </li>
                    <li>
                      Criação de um <b>Centro de Diagnósticos e Imagem.</b>
                    </li>
                    <li>
                      Implantação de <b>2 UPAs e 6 Policlínicas.</b>
                    </li>
                    <li>
                      <b>Ampliação do horário de funcionamento dos PSFs,</b>{" "}
                      abertos até às 21h.
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs2Open(!is2Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon22} alt="Mobilidade Urbana" />
                <h2>Mobilidade Urbana</h2>
              </motion.div>
              {is2Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <ul className="to-do-list">
                    <li>
                      Retomada das <b>linhas Bairro a Bairro,</b> com{" "}
                      <b>tarifa pela metade do valor normal.</b>
                    </li>
                    <li>
                      Criação de áreas de{" "}
                      <b>
                        embarque e desembarque para veículos de aplicativos.
                      </b>
                    </li>
                    <li>
                      Reestruturação da <b>integração temporal.</b>
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs3Open(!is3Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon23} alt="Cultura" />
                <h2>Cultura</h2>
              </motion.div>
              {is3Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <ul className="to-do-list">
                    <li>
                      Um <b>Novo Centro Histórico:</b> Cidade Filipeia, com{" "}
                      <b>
                        novas moradias, fomento ao comércio e revitalização da
                        área.
                      </b>
                    </li>
                    <li>
                      <b>Novo Mercadão da Cultura e da Gastronomia:</b>{" "}
                      Revitalização completa do <b>Mercado Central.</b>
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs4Open(!is4Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon24} alt="Educação" />
                <h2>Educação</h2>
              </motion.div>
              {is4Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <ul className="to-do-list">
                    <li>
                      Construção de cinco <b>Centros de Educação Integral.</b>
                    </li>
                    <li>
                      Retomada e ampliação das <b>Escolas em Tempo Integral.</b>
                    </li>
                    <li>
                      Implementação da{" "}
                      <b>Ronda Escolar da Guarda Metropolitana</b> e instalação
                      de câmeras de vigilância em todas as escolas.
                    </li>
                    <li>
                      Lançamento do <b>Programa Pé-de-Meia</b> para alunos do
                      EJA, com <b>bolsas de R$ 200 por mês.</b>
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs5Open(!is5Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon25} alt="Acessibilidade" />
                <h2>Acessibilidade</h2>
              </motion.div>
              {is5Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <ul className="to-do-list">
                    <li>
                      Criação da{" "}
                      <b>
                        Secretaria de Inclusão e Direitos da Pessoa com
                        Deficiência.
                      </b>
                    </li>
                    <li>
                      <b>Garantia de prioridade total</b> para pessoas com
                      deficiência.
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Work;
