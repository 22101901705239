import React from "react";
import "../styles/header.scss";

import {
  FaInstagram,
  FaFacebookF,
  FaXTwitter,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa6";
import { motion } from "framer-motion";

import logotype from "../img/logo.png";
import mainHero from "../img/main-hero.png";
import button1 from "../img/button-1.png";
import button2 from "../img/button-2.png";

const Header = () => {
  return (
    <header className="main-header">
      <nav className="navigation container">
        <motion.div
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="logotype"
        >
          <a href="index.html">
            <img src={logotype} alt="Luciano Cartaxo - 13" />
          </a>
        </motion.div>
        <motion.ul
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.9 }}
          className="social-icons"
        >
          <li>
            <a
              href="https://instagram.com/luciano_cartaxo"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/LucianoCartaxoPires"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a
              href="https://x.com/Luciano_Cartaxo"
              target="_blank"
              rel="noreferrer"
            >
              <FaXTwitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@luciano_cartaxo"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube />
            </a>
          </li>
          <li>
            <a
              href="https://tiktok.com/@luciano_cartaxo"
              target="_blank"
              rel="noreferrer"
            >
              <FaTiktok />
            </a>
          </li>
        </motion.ul>
      </nav>
      <div className="main-hero">
        <div>
          <motion.img
            initial={{ opacity: 0, x: -500 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            src={mainHero}
            alt="Pra fazer muito mais e melhor"
          />
        </div>
      </div>
      <div className="main-buttons">
        <div className="container">
          <a href="PlanoGoverno_Luciano_Amanda.pdf" download>
            <motion.img
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 1.2 }}
              src={button1}
            />
          </a>
          <a
            href="https://drive.google.com/drive/folders/1kPzk-MoR3TEs29j55uQGDAeSrF066SJB"
            target="_blank"
            rel="noreferrer"
          >
            <motion.img
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 1.5 }}
              src={button2}
            />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
