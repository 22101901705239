import { motion } from "framer-motion";
import { useState } from "react";

import "../styles/work.scss";

import icon11 from "../img/icons/row-1-1.png";
import icon12 from "../img/icons/row-1-2.png";
import icon13 from "../img/icons/row-1-3.png";
import icon14 from "../img/icons/row-1-4.png";
import icon15 from "../img/icons/row-1-5.png";

const Work = () => {
  const [is1Open, setIs1Open] = useState(false);
  const [is2Open, setIs2Open] = useState(false);
  const [is3Open, setIs3Open] = useState(false);
  const [is4Open, setIs4Open] = useState(false);
  const [is5Open, setIs5Open] = useState(false);
  return (
    <div className="main-work">
      <div className="list-1">
        <div className="container">
          <h1>Foi Luciano que fez</h1>
          <p>
            Durante meus dois mandatos como prefeito de João Pessoa, liderei uma
            série de iniciativas que impactaram a cidade em diversas áreas.
          </p>
          <ul className="card">
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs1Open(!is1Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon11} alt="Saúde" />
                <h2>Saúde</h2>
              </motion.div>
              {is1Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <h3>Construção de UPAs</h3>
                  <p>
                    Implantou 3 das 4 UPAs da cidade, localizadas nos bairros
                    Valentina, Cruz das Armas e Bancários.
                  </p>
                  <h3>Hospital Prontovida</h3>
                  <p>
                    Criou o Hospital Prontovida com 140 leitos, ampliando
                    significativamente a capacidade de atendimento hospitalar.
                  </p>
                  <h3>Novos PSFs</h3>
                  <p>
                    Construiu 13 novos Postos de Saúde da Família (PSFs) do
                    zero, enquanto a gestão anterior havia deixado apenas 2 pela
                    metade.
                  </p>
                  <h3>Reforma e Ampliação de USFs</h3>
                  <p>
                    Reformou e ampliou 60 Unidades de Saúde da Família (USFs).
                  </p>
                  <h3>Central de Vacinação</h3>
                  <p>
                    Inaugurou a primeira Central de Vacinação do Nordeste,
                    equipada para refrigerar todas as vacinas.
                  </p>
                  <h3>Reforma de Policlínicas</h3>
                  <p>Realizou a reforma das 6 policlínicas da cidade.</p>
                  <h3>Concurso Público</h3>
                  <p>
                    Realizou concurso público com mais de 1.600 convocados na
                    área da saúde.
                  </p>
                  <h3>Casa Mãe Bebê</h3>
                  <p>
                    Criou a Casa Mãe Bebê, um espaço de acolhimento para mães
                    cujos filhos nascem com complicações e precisam permanecer
                    internados na Maternidade Cândida Vargas.
                  </p>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs2Open(!is2Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon12} alt="Educação" />
                <h2>Educação</h2>
              </motion.div>
              {is2Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <h3>Construção de Creches</h3>
                  <p>
                    Construiu <b>17 novas creches,</b> ampliando as vagas
                    <b>de 4 mil para 15 mil.</b>
                  </p>
                  <h3>Merenda Premiada</h3>
                  <p>
                    Recebeu <b>premiação do MEC pela qualidade</b> da merenda
                    escolar.
                  </p>
                  <h3>Vagas de Berçário</h3>
                  <p>
                    Criou <b>1.800 vagas de berçário.</b>
                  </p>
                  <h3>Escolas Municipalizadas</h3>
                  <p>
                    Municipalizou <b>27 creches do estado.</b>
                  </p>
                  <h3>Novas Escolas</h3>
                  <p>
                    Construiu 8 novas escolas, incluindo a primeira escola
                    bilíngue no Alto do Mateus e o Centro de Educação Integral
                    Mangabeira.
                  </p>
                  <h3>Expansão do Ensino Integral</h3>
                  <p>
                    Expandiu o ensino integral, deixando a gestão com 40 escolas
                    oferecendo essa modalidade.
                  </p>
                  <h3>Concurso para Educação</h3>
                  <p>
                    Realizou o maior concurso da história da educação de João
                    Pessoa, com 1.500 convocados.
                  </p>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs3Open(!is3Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon13} alt="Transporte Público" />
                <h2>Transporte Público</h2>
              </motion.div>
              {is3Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <h3>Faixa Exclusiva de Ônibus</h3>
                  <p>
                    Implementou faixas exclusivas de ônibus em corredores
                    estratégicos, reduzindo o tempo de deslocamento.
                  </p>
                  <h3>Ciclovias</h3>
                  <p>
                    Criou <b>52 km de novas ciclovias.</b>
                  </p>
                  <h3>Plano Municipal de Mobilidade Urbana</h3>
                  <p>Desenvolveu o plano municipal de mobilidade urbana.</p>
                  <h3>Linhas Bairro a Bairro</h3>
                  <p>
                    Introduziu as linhas de ônibus bairro a bairro a R$ 2,00,
                    medida descontinuada pela gestão seguinte.
                  </p>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs4Open(!is4Open)}
            >
              <motion.div layout="position" className="title">
                <img className="icon" src={icon14} alt="Habitação" />
                <h2>Habitação</h2>
              </motion.div>
              {is4Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <h3>Moradias Entregues</h3>
                  <p>
                    Entregou <b>10 mil casas</b> em João Pessoa.
                  </p>
                  <h3>Urbanização de Comunidades</h3>
                  <p>
                    Urbanizou comunidades importantes como{" "}
                    <b>Timbó, Maria de Nazaré</b> e <b>Bairro São José.</b>
                  </p>
                  <h3>Villa Sanhauá</h3>
                  <p>
                    Construiu a Villa Sanhauá, premiada nacionalmente como{" "}
                    <b>modelo de habitação de interesse social.</b>
                  </p>
                  <h3>Regularização Fundiária</h3>
                  <p>
                    Entregou <b>2.003 títulos de regularização fundiária</b> em
                    várias áreas da cidade.
                  </p>
                </motion.div>
              )}
            </motion.li>
            <motion.li
              transition={{ layout: { duration: 1, type: "spring" } }}
              layout
              style={{ borderRadius: "20px" }}
              onClick={() => setIs5Open(!is5Open)}
            >
              <motion.div layout="position" className="title">
                <img
                  className="icon"
                  src={icon15}
                  alt="Desenvolvimento Social"
                />
                <h2>Desenvolvimento Social</h2>
              </motion.div>
              {is5Open && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="expand"
                >
                  <h3>CRAS</h3>
                  <p>
                    Construiu <b>Centros de Referência de Assistência Social</b>{" "}
                    nos bairros Gervásio Maia e Vale das Palmeiras.
                  </p>
                  <h3>Reforma de Conselhos Tutelares </h3>
                  <p>
                    Reformou <b>todas as unidades dos Conselhos Tutelares</b> de
                    João Pessoa.
                  </p>
                  <h3>Restaurantes Populares</h3>
                  <p>
                    Construiu o <b>Restaurante Popular de Mangabeira</b> e
                    instalou outro no Parque da Lagoa.
                  </p>
                </motion.div>
              )}
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Work;
