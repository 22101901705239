import "../styles/footer.scss";

import logotype from "../img/logo-w.png";
import button3 from "../img/button-3.png";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <a href="index.html">
          <img src={logotype} alt="Luciano Cartaxo - 13" />
        </a>
        <div className="button">
          <a
            href="https://whatsapp.com/channel/0029VagWYJd1Hsq6HcdgUL2v"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={button3}
              alt="Vem pro time de Lula, Luciano e Amanda no Zap!"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
